<template>
    <div>
      <passport-report></passport-report>
    </div>
  </template>
  
  <script>
  import PassportReport from "@/components/ecologist/reports/passport/PassportReport";
  export default {
    name: "PassportView",
    components: { PassportReport },
  };
  </script>
  
  <style scoped></style>